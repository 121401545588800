import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import MobileMenu from '../MobileMenu/MobileMenu'
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';
import Search from '../../components/Search/Search';
const Header2 = (props) => {

    // const [menuActive, setMenuState] = useState(false);
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const { apiSettings } = useAppContext();
    const location = useLocation(); // 
    const bookLink = process.env.REACT_APP_SQUARE_BOOK_LINK;
    console.log(bookLink);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    // console.log( apiSettings );
    const catServices = apiSettings.settings.catServices;
    const info = apiSettings.settings;

    return (
        <header className="absolute left-0 top-0 w-full z-[111] lg:relative">
            <div className="bg-[#4B424D] pt-[5px] relative before:absolute before:w-full before:h-[1px] before:bg-[#C0B596] before:bottom-0 before:left-0 before:content-['']">
                <div className="wraper min-w-[90%]">
                    <div className="grid grid-cols-12">
                        <div className="col-span-10 md:col-span-12">
                            <ul className="text-left md:text-center">
                                <li className="text-white inline-block p-[15px]  col:pr-0 pl-0 col:pb-[0]">
                                    <a target="_blank" href="https://maps.app.goo.gl/FiDtks1ZXbxnhZhh9" rel="noreferrer">
                                        <i className="fa fa-map-marker pr-[10px] col:pt-[0] text-[#C0B596] text-[20px]"
                                            aria-hidden="true"></i>
                                        {info.full_address}
                                    </a>
                                </li>
                                <li className="relative text-white  inline-block p-[15px]  col:pr-0
                                    before:absolute before:content-[''] before:left-0 before:top-[15px] before:w-[1px] before:h-[25px] 
                                    before:bg-[rgba(255,255,255,.07)]  before:z-10 before:transform before:-translate-x-1/2 md:before:hidden">
                                    <a href={`tel:+1${info.phone}`} >
                                        <i className="fa fa-mobile pr-[10px] text-[#C0B596] text-[20px]" aria-hidden="true"></i>
                                        {t('Phone')}: {info.phone_formated}
                                    </a>
                                </li>
                                <li className="relative text-white  inline-block p-[15px] col:pr-0  before:absolute before:content-[''] before:left-0 before:top-[15px] before:w-[1px] before:h-[25px] before:bg-[rgba(255,255,255,.07)] md:before:hidden  before:z-10 before:transform before:-translate-x-1/2 ">
                                    <i className="fa fa-clock-o pr-[10px] text-[#C0B596] text-[20px]" aria-hidden="true"></i>
                                    {info.schedule.Today.status === 'closed' ? (
                                        t(info.schedule.Today.status)
                                    ) : (
                                        `${info.schedule.Today.opening_formated} - ${info.schedule.Today.closing_formated}`
                                    )}
                                </li>
                                <li className="relative text-white inline-block p-[15px] col:pr-0  before:absolute before:content-[''] before:left-0 before:top-[15px] before:w-[1px] before:h-[25px] before:bg-[rgba(255,255,255,.07)] md:before:hidden  before:z-10 before:transform before:-translate-x-1/2 ">
                                    <span className="flex">
                                        <i className="fa fa-clock-o pr-[10px] text-[#C0B596] text-[20px]" aria-hidden="true"></i>
                                        <button
                                            onClick={() => changeLanguage('en')}
                                            className={`relative group text-[15px] lg:text-[14px] block uppercase font-base-font font-normal hover:text-white
                                            transition-all ${lang === 'en' ? 'text-[#C0B596]' : 'text-white'}`}>
                                            En
                                        </button>
                                        <span className="mx-2 text-white">/</span>
                                        <button
                                            onClick={() => changeLanguage('fr')}
                                            className={`relative group text-[15px] lg:text-[14px]  block uppercase font-base-font font-normal hover:text-white
                                            transition-all ${lang === 'fr' ? 'text-[#C0B596]' : 'text-white'}`}>
                                            Fr
                                        </button>
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className="col-span-2 md:col-span-12">
                            <div className="text-right mt-1 md:text-center md:mb-[15px]">
                                <Link onClick={ClickHandler} to={bookLink} target="_blanck" className="text-white theme-btn md:text-[12px] md:py-[8px] md:px-[22px]">
                                    {t('Book now')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-site-header bg-[#4b424da8] lg:bg-[#4B424D] relative ">
                <div className="wraper min-w-[90%]">
                    <div className="flex items-center justify-between ">
                        <MobileMenu catServices={catServices} lang={lang} />
                        <div className="md:flex hidden"  >
                        <Search currentLanguage={lang} />
                        </div>
                        <div className="logo w-[80px] md:w-[90px] md:px-[25px] md:pl-0  col:w-[100px]" title={t('Logo')}  >
                            <Link onClick={ClickHandler} className="text-[#C0B596] font-semibold flex items-center  whitespace-no-wrap" to="/">
                                <img className="w-full md:block" src={info.logo_header_path} alt="" />
                                <span className="ml-2 font-semibold md:hidden text-[24px] uppercase" style={{ fontFamily: "'Playfair Display', sans-serif" }} >
                                    {info.app_name[lang]}
                                </span>
                            </Link>
                        </div>
                       
                        <ul className="md:hidden mr-[-50px] lg-[-10px]">
                            <li className="relative inline-block group">
                                <Link
                                    onClick={ClickHandler}
                                    to="/"
                                    className={`relative text-[15px] lg:text-[14px] py-[35px] xl:py-[30px] px-[10px] xl:px-[6px] block uppercase font-base-font font-bold transition-all ${location.pathname === '/' ? 'text-[#C0B596]' : 'text-white hover:text-[#C0B596]'
                                        }`}
                                >
                                    {t('Home')}
                                </Link>
                            </li>
                            <li className="relative inline-block">
                                <Link
                                    onClick={ClickHandler}
                                    to="/about"
                                    className={`relative group text-[15px] lg:text-[14px] py-[35px] xl:py-[30px] px-[10px] xl:px-[6px] block uppercase font-base-font font-bold transition-all ${location.pathname === '/about' ? 'text-[#C0B596]' : 'text-white hover:text-[#C0B596]'
                                        }`}
                                >
                                    {t('About')}
                                </Link>
                            </li>
                            <li className="relative inline-block">
                                <Link
                                    onClick={ClickHandler}
                                    to="/promotions"
                                    className={`relative group text-[15px] lg:text-[14px] py-[35px] xl:py-[30px] px-[10px] xl:px-[6px] block uppercase font-base-font font-bold transition-all ${location.pathname === '/promotions' ? 'text-[#C0B596]' : 'text-white hover:text-[#C0B596]'
                                        }`}
                                >
                                    {t('Promotions')}
                                </Link>
                            </li>
                            <li className="relative inline-block">
                                <Link
                                    onClick={ClickHandler}
                                    to="/prices"
                                    className={`relative group text-[15px] lg:text-[14px] py-[35px] xl:py-[30px] px-[10px] xl:px-[6px] block uppercase font-base-font font-bold transition-all ${location.pathname === '/prices' ? 'text-[#C0B596]' : 'text-white hover:text-[#C0B596]'
                                        }`}
                                >
                                    {t('Our Prices')}
                                </Link>
                            </li>
                            <li className="relative inline-block group">
                                <Link
                                    onClick={ClickHandler}
                                    to={`/cat-service/${catServices[0].slug}`}
                                    className={`relative group text-[15px] lg:text-[14px] py-[35px] xl:py-[30px] px-[10px] xl:px-[6px] block uppercase font-base-font font-bold transition-all ${location.pathname.startsWith('/cat-service') ? 'text-[#C0B596]' : 'text-white hover:text-[#C0B596]'
                                        }`}
                                >
                                    {t('Categories')}
                                </Link>
                                <ul className="absolute w-[240px] left-0 top-[110%] p-[20px] z-[111] bg-[#4b424da8] shadow-[#3e419f17] transition-all opacity-0 invisible group-hover:opacity-100 group-hover:top-full group-hover:visible">
                                    {catServices.map((catService, item) => (
                                        <li className="mb-[10px]" key={item}>
                                            <Link
                                                onClick={ClickHandler}
                                                to={`/cat-service/${catService.slug}`}
                                                className={`text-[15px] lg:text-[14px] inline-block py-[5px] uppercase font-bold transition-all ${location.pathname === `/cat-service/${catService.slug}` ? 'text-[#C0B596]' : 'text-white hover:text-[#C0B596]'
                                                    }`}
                                            >
                                                {catService.name[lang]}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="relative inline-block">
                                <Link
                                    onClick={ClickHandler}
                                    to="/contact"
                                    className={`relative group text-[15px] lg:text-[14px] py-[35px] xl:py-[30px] px-[10px] xl:px-[6px] block uppercase font-base-font font-bold transition-all ${location.pathname === '/contact' ? 'text-[#C0B596]' : 'text-white hover:text-[#C0B596]'
                                        }`}
                                >
                                    {t('Contact')}
                                </Link>
                            </li>
                            <li className="relative md:hidden inline-block">
                                <Search currentLanguage={lang} /> {/* Barre de recherche visible sur toutes les pages */}
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header2;