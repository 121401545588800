import React, { Fragment, useState, useEffect } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import CategoriesFooter from '../../components/CategoriesFooter';
import Navbar2 from '../../components/Navbar2/Navbar2';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';
import { Link } from 'react-router-dom';

const Prices = () => {
    const { apiSettings } = useAppContext();
    const { t, i18n } = useTranslation();
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };
    // État pour suivre le collapse des catégories et des services
    const [isCategoryOpen, setIsCategoryOpen] = useState({});
    const [isServiceOpen, setIsServiceOpen] = useState({});


    useEffect(() => {
        if (apiSettings.settings) {
            const initialCategoryState = {};
            const initialServiceState = {};

            apiSettings.settings.catServices.forEach((catService, catIndex) => {
                initialCategoryState[catIndex] = true; // Toutes les catégories sont ouvertes par défaut

                catService.services_active.forEach((service) => {
                    initialServiceState[service.id] = true; // Tous les services sont ouverts par défaut
                });
            });

            setIsCategoryOpen(initialCategoryState);
            setIsServiceOpen(initialServiceState);
        }
    }, [apiSettings.settings]);
    // Fonction pour basculer le collapse d'une catégorie
    const toggleCategoryCollapse = (categoryId) => {
        setIsCategoryOpen((prevState) => ({
            ...prevState,
            [categoryId]: !prevState[categoryId],
        }));
    };

    // Fonction pour basculer le collapse d'un service
    const toggleServiceCollapse = (serviceId) => {
        setIsServiceOpen((prevState) => ({
            ...prevState,
            [serviceId]: !prevState[serviceId],
        }));
    };

    // Affichage du spinner si les données ne sont pas encore chargées
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }

    const info = apiSettings.settings;
    const lang = i18n.language;
    const catServices = info.catServices;
    const promotions = info.promotion;
    const itemsPromotions = promotions.flatMap((promo) => promo.items);


    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={t('Our Prices')} pagesub={t('Our Prices')} />

            <div className="py-[30px] md:py-[2px]">
                <div className="wraper">
                    <div className="flex w-full">
                        <div className="flex-row w-full">
                            {catServices.map((catService, Citem) => (
                                <div className="mt-[25px] w-full" key={Citem}>
                                    <div className="mb-8 organigation-text">
                                        {/* Titre de catégorie avec collapse */}
                                        <h2
                                            className="text-[24px]  mb-[8px] block cursor-pointer"
                                            onClick={() => toggleCategoryCollapse(Citem)}
                                        >
                                            <i
                                                className={`text-[#C0B596] fa ${isCategoryOpen[Citem] ? 'fa-chevron-down' : 'fa-chevron-right'} pr-2`}
                                            ></i>
                                            {/* <Link onClick={ClickHandler} to={`/cat-service/${catService.slug}`}>
                                              
                                            </Link> */}
                                            {catService.name[lang]}
                                        </h2>

                                        {/* Liste des services si la catégorie est ouverte */}
                                        {isCategoryOpen[Citem] && catService.services_active.map((service, item) => {


                                            // Affichage direct si un seul variant
                                            if (service.variants_active.length === 1) {
                                                const variant = service.variants_active[0];
                                                const promotionItem = itemsPromotions.find(
                                                    (promo) => promo.service_variant_id === variant.id
                                                );
                                                return (
                                                    <div
                                                        key={item}
                                                        className="ml-2 lg:flex md:block items-center text-[16px] text-[#333] pb-3 lg:border-0 md:border-b"
                                                    >
                                                        <i
                                                            className="fa fa-lg fa-star text-[#C0B596] pr-[15px]"
                                                            aria-hidden="true"
                                                        ></i>
                                                        <span className="w-2/4">
                                                            <Link
                                                                onClick={ClickHandler}
                                                                to={`/service/${catService.slug}/${service.id}`}
                                                                className="transition ease-in-out duration-300 hover:text-[#C0B596]"
                                                            >
                                                                {variant.name[lang]}
                                                            </Link>
                                                        </span>
                                                        <span className="lg:flex md:block md:justify-center lg:justify-end ml-10 w-full">
                                                            {promotionItem ? (
                                                                <s className="text-[12px] line-through text-red-500">
                                                                    {promotionItem.price_before}
                                                                </s>
                                                            ) : (
                                                                `${variant.price_formated} / ${variant.unit.name[lang]}`
                                                            )}
                                                        </span>
                                                    </div>
                                                );
                                            }

                                            // Affichage des services avec plusieurs variantes et collapse
                                            return (
                                                <div key={item} className="ml-2">
                                                    {/* Titre du service avec collapse */}
                                                    <div
                                                        className="lg:flex md:block items-center text-[16px] text-[#333] pb-3 lg:border-0 md:border-b cursor-pointer"
                                                        onClick={() => toggleServiceCollapse(service.id)}
                                                    >
                                                        <i
                                                            className={`fa ${isServiceOpen[service.id] ? 'fa-chevron-down' : 'fa-chevron-right'} text-[#C0B596] pr-[15px]`}
                                                            aria-hidden="true"
                                                        ></i>
                                                        <span className="w-2/4">
                                                            <i
                                                                className="fa fa-star fa-lg text-[#C0B596] pr-[15px]"
                                                                aria-hidden="true"
                                                            ></i>
                                                            {service.name[lang]}
                                                        </span>
                                                    </div>

                                                    {/* Variantes de service affichées si le collapse est ouvert */}
                                                    {isServiceOpen[service.id] && service.variants_active.map((variant, variantIndex) => {

                                                        const promotionItem = itemsPromotions.find(
                                                            (promo) => promo.service_variant_id === variant.id
                                                        );
                                                        return (
                                                            <div
                                                                key={variantIndex}
                                                                className="lg:flex md:block ml-8 items-center text-[16px] text-[#333] pb-3 lg:border-0 md:border-b"
                                                            >
                                                                <i
                                                                    className="fa fa-star text-[#C0B596] pr-[15px]"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                <span className="w-2/4">
                                                                    <Link
                                                                        onClick={ClickHandler}
                                                                        to={`/service/${catService.slug}/${service.id}`}
                                                                        className="transition ease-in-out duration-300 hover:text-[#C0B596]"
                                                                    >
                                                                        {variant.name[lang]}
                                                                    </Link>
                                                                </span>

                                                                {promotionItem ? (
                                                                    <span className="lg:flex md:block md:justify-center lg:justify-end ml-10 w-full">
                                                                        <s className="text-[12px] line-through text-red-500">
                                                                            {variant.price_formated} 
                                                                        </s>
                                                                        <span className="text-[#333]">
                                                                             / {variant.unit.name[lang]}
                                                                        </span>
                                                                    </span>
                                                                ) : (
                                                                    <span className="lg:flex md:block md:justify-center lg:justify-end ml-10 w-full">
                                                                        ${variant.price_formated} / ${variant.unit.name[lang]}
                                                                    </span>
                                                                )}

                                                            </div>
                                                        );
                                                    }
                                                    )}

                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <ConsultingArea />
            <div className="py-[100px] md:py-[80px]">
                <div className="wraper">
                    <CategoriesFooter lang={lang} catServices={catServices} />
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default Prices;
