import React, { Fragment } from 'react';

import Navbar2 from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { useParams ,Link} from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Sidebar from './Sidebar';
import Newsletter from '../../components/Newsletter/Newsletter';
import CategoriesFooter from '../../components/CategoriesFooter';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';
const CategoryServiceSinglePage = (props) => {
    const { slug } = useParams();
    const { i18n } = useTranslation();
    const { apiSettings } = useAppContext();
     if (!apiSettings.settings || apiSettings.loading) {
        return (
                <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const info = apiSettings.settings;
    const lang = i18n.language; 
    const catServices = info.catServices;
   
    const CateroryService = catServices.find(item => item.slug === slug);
    const servicesInCat = CateroryService.services_active;
    // console.log('servicesInCat', servicesInCat);


    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={CateroryService.name[lang]} pagesub={CateroryService.name[lang]} image_path={CateroryService.image_path} />
            <div className="py-[100px] md:py-[80px]">
                <div className="wraper">
                    <div className="grid grid-cols-12 gap-3">
                    <Sidebar services={servicesInCat} lang={lang} slug={slug} />
                        <div className="col-span-8 md:col-span-12 order-2 md:order-1">
                            {/* <img className="w-full" src={CateroryService.image_path} alt="" /> */}
                            <div className="pt-[5px]">
                                <h2 className="text-[36px] text-[#282e3f] mb-[25px]">{CateroryService.name[lang]}</h2>
  
                                 <p className="text-[16px] leading-[30px] py-[20px] mb-[16px]">
                                    <span dangerouslySetInnerHTML={{ __html: CateroryService.description[lang]}}></span>  
                                </p>
                                <p className="text-[16px] leading-[30px] py-[20px] mb-[16px]">
                                    <span dangerouslySetInnerHTML={{ __html: CateroryService.des2[lang]}}></span>  
                                </p>
                                <p className="text-[16px] leading-[30px] mb-[25px]">
                                <span dangerouslySetInnerHTML={{ __html: CateroryService.des3[lang]}}></span>
                                </p>
                            </div>
                            <div className="mt-[40px]">
                                {/* <div className="float-left col:float-none mr-[30px] col:mr-0 col:mb-[30px] w-[30%] col:w-full">
                                    <img src={pImg} alt="" />
                                </div> */}
                                <div className="organigation-text  col:float-none col:w-full">
                                    <h2 className="text-[24px] text-[#282e3f] mb-[8px] block">{CateroryService.name[lang]}</h2>
                                    {servicesInCat.map((service, item) => (
                                        <div key={item} className="flex items-center pt-[15px] text-[15px] text-[#333]">
                                            <i className="fa fa-check-square-o text-[#C0B596] pr-[15px]" aria-hidden="true"></i>
                                            <span className="w-2/3">
                                                <Link onClick={ClickHandler} to={`/service/${slug}/${service.id}`} 
                                            
                                                    className="transition ease-in-out duration-300 hover:text-[#C0B596]">
                                                    {service.name[lang]} 
                                                </Link>    
                                            </span>
                                            {/* <span className="w-1/3 ml-3 ">{service.duration} min</span>
                                            <span className="w-1/3">{service.price_formated} / {service.unit.name[lang]} </span> */}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConsultingArea />
            <div className="py-[100px] md:py-[80px]">
                <div className="wraper">
                    <CategoriesFooter lang={lang} catServices={catServices} />
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default CategoryServiceSinglePage;
