import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import CategoriesFooter from '../../components/CategoriesFooter';

import Navbar2 from '../../components/Navbar2/Navbar2';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';
import { Link } from 'react-router-dom';

const Promotions = () => {
    const { apiSettings } = useAppContext();
    const { t, i18n } = useTranslation();

    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }

    const info = apiSettings.settings;
    const lang = i18n.language;
    const catServices = info.catServices;
    const promotions = info.promotion;
    // Promotions sans catégorie (category_services_id === null)
    // const promotionsWithoutCategory = promotions.filter(
    //     (promotion) => promotion.category_id === null
    // );
    const promotionWithCategorie = promotions.filter(
        (promotion) => promotion.item_category_id !== null
    );
    console.log('promotionWithCategorie', promotionWithCategorie);

    const promotionsSpecial = promotions.filter(
        (promotion) => promotion.type_promo === 'special'
    );

    const promotionsByService = promotions.filter(
        (promotion) => promotion.type_promo === 'by_service'
    );

    console.log('promotionsByService', promotionsByService);
    const promotionsCombo = promotions.filter(
        (promotion) => promotion.type_promo === 'combo'
    );

    // console.log('promotionsWithoutCategory', promotionsWithoutCategory);
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={t('Promotions')} pagesub={t('Promotions')} />


            <div className="py-[30px] md:py-[2px]">
                <div className="wraper">
                    <div className="flex w-full">
                        <div className="flex-row w-full">
                            {catServices.map((category) => {
                                // Filtrer les promotions par ID de catégorie
                                const promotionInCategories = promotionWithCategorie.filter(
                                    (promotion) => promotion.item_category_id === category.id
                                );
                                // console.log('promotionInCategories', promotionInCategories);

                                // Si des promotions existent pour cette catégorie, les rendre
                                return promotionInCategories.length > 0 ? (
                                    <div className="mt-[25px] w-full" key={category.id}>
                                        <div className="mb-8 organigation-text">
                                            <h2 className="text-[24px] text-[#282e3f] mb-[8px] block">
                                                <Link onClick={ClickHandler} to={`/cat-service/${category.slug}`}>
                                                    {category.name[lang]}
                                                </Link>
                                            </h2>

                                            {promotionInCategories.map((promotion) => {
                                                const service = category.services_active.find(
                                                    (service) => service.id === promotion.item_service_id
                                                );

                                                return service ? (
                                                    <div key={promotion.id} className="mb-5 lg:flex md:block items-center text-[18px] text-[#333] pb-3 lg:border-0 md:border-b">
                                                        <i className="fa fa-star text-[#C0B596] pr-[15px]" aria-hidden="true"></i>
                                                        <span className="w-full">
                                                            <Link
                                                                onClick={ClickHandler}
                                                                to={`/service/${category.slug}/${service.id}`}
                                                                className="transition ease-in-out duration-300 hover:text-[#C0B596]"
                                                            >
                                                               {promotion.name[lang]}
                                                            </Link>
                                                        </span>
                                                        <span className="lg:flex md:block md:justify-center lg:justify-end ml-5 w-full">
                                                            <s className="text-[15px] line-through text-red-500 mr-2">{promotion.price_before} $</s>
                                                            <span className="text-black">{promotion.price_after} $</span>
                                                            <span className="text-black"> / {promotion.unit.name[lang]} </span>
                                                        </span>
                                                        <div className="flex  items-center ml-10 w-full">
                                                            <span className="w-full text-[14px] text-[#C0B596]" > 
                                                                { promotion.start_date_formated} - {promotion.end_date_formated}
                                                            </span>
                                                        </div>
                                                     
                                                        {promotion.description[lang] && (
                                                            <div className="flex  items-center ml-5 w-[90%]">
                                                                <i className="fa fa-info-circle text-[#b6b6ad] pr-[15px]" aria-hidden="true"></i>
                                                                <span className="text-[14px]" dangerouslySetInnerHTML={{ __html: promotion.description[lang] }} /> {/* Render HTML safely */}

                                                            </div>
                                                        )}

                                                    </div>
                                                ) : null;
                                            })}
                                        </div>
                                    </div>
                                ) : null;
                            })}
                            {/* Section pour les promotions combo */}
                            {promotionsCombo.length > 0 && (
                                <div className="mt-[25px] w-full">
                                    <h2 className="text-[24px] text-[#282e3f] mb-[8px] block">
                                        {t('Combo')}
                                    </h2>
                                    {promotionsCombo.map((promotion) => (
                                        <div key={promotion.id} className="mb-5 lg:flex md:block items-center text-[20px] text-[#333] pb-3 lg:border-0 md:border-b">
                                            <i className="fa fa-star text-[#C0B596] pr-[15px]" aria-hidden="true"></i>
                                            <span className="w-full">
                                                {promotion.name[lang]}
                                            </span>
                                           
                                            <span className="lg:flex md:block md:justify-center lg:justify-end ml-5 w-full">
                                                <s className="text-[18px] line-through text-red-500 mr-2">{promotion.price_before} $</s>
                                                <span className="text-black">{promotion.price_after} $</span>
                                                <span className="text-black"> / {promotion.unit.name[lang]} </span>
                                            </span> 
                                            <div className="flex items-center ml-10 w-full">
                                                <span className="w-full text-[14px] text-[#C0B596]" > 
                                                    { promotion.start_date_formated} - {promotion.end_date_formated}
                                                </span>
                                            </div>  
                                            {promotion.description[lang] && (
                                                <div className="flex items-center ml-8 w-[90%]">
                                                    <span className="text-[16px]" dangerouslySetInnerHTML={{ __html: promotion.description[lang] }} /> {/* Render HTML safely */}
                                                </div>
                                            )}
                                              {promotion.items.map((item) => (
                                                 <div className="text-[14px] lg:flex md:block md:justify-center lg:justify-end ml-8 w-full">
                                                    <i className="fa fa-star text-[#C0B596] pr-[15px]"
                                                     aria-hidden="true"></i>
                                                      <Link onClick={ClickHandler}
                                                                to={`/service/${item.category.slug}/${item.serviceV.id}`}
                                                                className="transition ease-in-out duration-300 hover:text-[#C0B596]"
                                                            >                                                                
                                                               {item.serviceV.name[lang]} -  {item.name[lang]}
                                                    </Link>
                                               
                                                    <span className="lg:flex md:block md:justify-center lg:justify-end ml-10 w-full">
                                                        {item.price_before} $ / {item.unit.name[lang]}
                                                    </span>
                                                        
                                                </div>
                                             ))}
                                                                             
                                          
                                        </div>
                                    ))}

                                </div>
                            )}

                            {/* Section pour les promotions sans catégorie */}
                            {promotionsSpecial.length > 0 && (
                                <div className="mt-[25px] w-full">
                                    <h2 className="text-[24px] text-[#282e3f] mb-[8px] block">
                                        {t('Specials')}
                                    </h2>
                                    {promotionsSpecial.map((promotion) => (
                                        <div key={promotion.id} className="mb-5 lg:flex md:block items-center text-[20px] text-[#333] pb-3 lg:border-0 md:border-b">
                                            <i className="fa fa-star text-[#C0B596] pr-[15px]" aria-hidden="true"></i>
                                            <span className="w-full">
                                                {promotion.name[lang]}
                                            </span>
                                            <span className="lg:flex md:block md:justify-center lg:justify-end ml-5 w-full">
                                                <s className="text-[18px] line-through text-red-500 mr-2">{promotion.price_before} $</s>
                                                <span className="text-black">{promotion.price_after} $</span>
                                                <span className="text-black"> / {promotion.unit.name[lang]} </span>
                                            </span>
                                            <div className="flex  items-center ml-10 w-full">
                                                <span className="w-full text-[14px] text-[#C0B596]" > 
                                                    { promotion.start_date_formated} - {promotion.end_date_formated}
                                                </span>
                                            </div>
                                            {promotion.description[lang] && (
                                                <div className="flex items-center ml-8 w-[90%]">
                                                    <span className="text-[16px]" dangerouslySetInnerHTML={{ __html: promotion.description[lang] }} /> {/* Render HTML safely */}
                                                </div>
                                            )}
                                            

                                        
                                        </div>
                                    ))}

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ConsultingArea />
            <div className="py-[100px] md:py-[80px]">
                <div className="wraper">
                    <CategoriesFooter lang={lang} catServices={catServices} />
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default Promotions;
