import React  from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo_G.png';
import { useAppContext } from '../../api/AppSettings';
import { useTranslation } from 'react-i18next';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';

const SubmitHandler = (e) => {
    e.preventDefault();
    const url = e.currentTarget.getAttribute('data-url');
    window.open(url, '_blank'); 
}



const Footer = (props) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const {  apiSettings } = useAppContext();
    const currentYear = new Date().getFullYear();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const info = apiSettings.settings;  
    
    return (
        <footer className="relative bg-[#201C21] z-10">
            <div className="pt-[50px] pb-[30px] md:py-[90px] md:pb-[20px] overflow-hidden relative -z-10">
                <div className="wraper">
                    <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-3">

                        <div className="w-[200px] md:w-full ml-auto md:mb-[40px] lg:pr-0 relative text-left ">
                            <ul>
                                <li className="relative mb-[8px] block">
                                    <Link className=" text-white hover:text-[#C0B596] transition-all" to="/home">
                                       {t('Home')}
                                    </Link></li>
                                <li className="relative mb-[8px] block">
                                    <Link className=" text-white hover:text-[#C0B596] transition-all" to="/about">
                                       {t('About us')}
                                    </Link>
                                </li>                          
                                <li className="relative mb-[8px] block">
                                    <Link className=" text-white hover:text-[#C0B596] transition-all" to="/promotions">
                                    {t('Promotions')}
                                    </Link>
                                </li>
                                <li className="relative mb-[8px] block">
                                    <Link className=" text-white hover:text-[#C0B596] transition-all" to="/prices">
                                    {t('Our Prices')}
                                    </Link>
                                </li>
                                <li className="relative mb-[8px] block">
                                    <Link className=" text-white hover:text-[#C0B596] transition-all" to="/contact">
                                       {t('Contact Us')}
                                    </Link>
                                </li>
                                 <li className="relative mb-[8px] block">
                                    <span className="flex">
                                        <button 
                                            onClick={() => changeLanguage('en')} 
                                            className={`relative group text-[15px] lg:text-[14px] block uppercase font-base-font font-normal hover:text-[#C0B596]
                                            transition-all ${lang === 'en' ? 'text-[#C0B596]' : 'text-white'}`}>
                                            En
                                        </button>
                                        <span className="mx-2 text-white">/</span>
                                        <button 
                                            onClick={() => changeLanguage('fr')} 
                                            className={`relative group text-[15px] lg:text-[14px]  block uppercase font-base-font font-normal hover:text-[#C0B596]
                                            transition-all ${lang === 'fr' ? 'text-[#C0B596]' : 'text-white'}`}>
                                            Fr
                                        </button>
                                        </span>
                                 </li>
                            </ul>
                        </div>
  
                        <div className="pl-[15px] md:pl-[0px] md:mb-[40px] lg:pr-0 relative text-left ">
                            <ul className="text-[14px]" >
                            {Object.entries(info.schedule).map(([day, daySchedule]) => (
                                (day !== 'Today' ? (
                            <li className="relative mb-[1px] block text-white" key={day}>
                                {t(day)}: {' '}                          
                                {daySchedule.status === 'open' ? `${daySchedule.opening_formated} - ${daySchedule.closing_formated}` : `${t(daySchedule.status)}`}
                            </li>
                             ) : null)
                            ))}
                               
                            </ul>
                        </div>
                        <div className=" md:mb-[10px] lg:pr-0 relative text-left ">
                            <ul>
                                <li className="relative mb-[8px] block text-white">
                                    <a target="_blank" href="https://maps.app.goo.gl/FiDtks1ZXbxnhZhh9">   
                                        {info.full_address}
                                    </a>
                                </li>
                                <li className="relative mb-[8px] block text-white"  >
                                <a  href={`tel:+1${info.phone}`} >
                                    {t('Phone')}: {info.phone_formated}
                                </a>
                                </li>
                                <li className="relative mb-[8px] block text-white">
                                    <a href="/contact">
                                    {t('Email')}: {info.email}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="relative text-center">
                            <div className="flex justify-center mb-1">
                                <Link className="text-[45px] font-bold  items-center text-white w-[105px]"  to="/">
                                    <img src={Logo} alt="" /></Link>
                            </div>
                            <ul className="overflow-hidden flex  justify-center items-center text-center">
                                <li className="text-white  group ">
                                    <Link className="text-white transition-all group-hover:text-[#CBB26A]" onClick={SubmitHandler} 
                                        to='#' data-url={info.facebook}><i className="ti-facebook"></i>
                                    </Link>
                                </li>
                                <li className="text-white  group ml-[15px]">
                                    <Link className="text-white transition-all group-hover:text-[#CBB26A]" onClick={SubmitHandler} 
                                        to='#' data-url={info.linkedin}><i className="ti-linkedin"></i>
                                    </Link>
                                </li>
                                <li className="text-white  group ml-[15px]">
                                    <Link className="text-white transition-all group-hover:text-[#CBB26A]" onClick={SubmitHandler}
                                     to='#' data-url={info.instagram}><i className="ti-instagram"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wraper">
                <div className=" border-t-1 border-[rgba(192,181,150,.3)] relative">
                    <div className="h-[1px] absolute left-[15px] top-0 bg-[#ffffff0d] w-[calc(100%+30px)]"></div>
                    <p className="text-center text-white text-[14px] py-[20px]"> 
                    <Link className="" onClick={SubmitHandler}
                         to='#' data-url='/privacy-policies'>{t('Privacy Policy')}
                        </Link>  | 
                     {info.app_name[lang]} &copy; {currentYear}</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;